<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <span class="text-h5">
            User Profile
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <table width="100%" border="1">
            <tr>
              <td>
                NAME
              </td>
              <td>{{ userInfo.firstName }} {{ userInfo.lastName }}</td>
            </tr>
            <tr>
              <td>
                USERNAME
              </td>
              <td>{{ userInfo.email }}</td>
            </tr>
            <tr>
              <td>
                EMAIL
              </td>
              <td>{{ userInfo.email }}</td>
            </tr>
            <tr>
              <td>
                PASSWORD
              </td>
              <td>***********</td>
            </tr>
          </table>
        </v-col>
        <v-col cols="4">
          <div>
            <MediaUploader
              class="pb-5"
              uploadType="photo"
              :key="profilePictureObj.id"
              :id="`userPicture_${profilePictureObj.id}`"
              :index="profilePictureObj.id"
              :accept="accept"
              :multiple="false"
              :extensions="extensions"
              :sizeImage="10485760"
              :sizeVideo="104857600"
              :fileId.sync="profilePictureObj.fileId"
              :file-url.sync="profilePictureObj.url"
              :isUploading.sync="isFileUploading"
              @onMultipleUploaded="onMultipleAddFile"
              @onChange="onChangeUserPicture"
              @onDeleteFile="onDeleteFile"
            />
            <v-btn
              @click="updateUserPicture"
              v-if="showUpdateUserPictureButton"
              :loading="userPictureLoading"
            >
              Save Profile Picture
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <span class="text-h5">
            Change your password
          </span>
          <p>
            You can change your password whenever you like. Your password must
            be a minimum of 8 characters and contain one capital letter and
            either a number or a character.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="newPassword"
            label="New Password"
            required
          ></v-text-field>
          <v-text-field
            v-model="confirmNewPassword"
            label="Confirm New Password"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn class="orange-bg white--text">Update Password</v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <v-col cols="12">
          <span class="text-h5">
            Alert Preference
          </span>
          <p>
            You can choose how to receive alerts by email or text. Anytime a new
            message is sent to you or there is an update to a service request
            created by you or assigned to you, you will receive an alert by
            email unless otherwise indicated.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col>
              <v-checkbox
                v-model="useEmailAlerts"
                label="Email"
                class="v-checkbox-no-margin"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Notify me when:
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-for="(notification, i) in dbNotifications"
                :key="'email_notification_checkbox_' + i"
                v-model="emailNotifications"
                :label="notification.description"
                :value="notification.id"
                color="blue"
                class="v-checkbox-no-margin"
                :readonly="!useEmailAlerts"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                @click="savePreferences"
                class="orange-bg white--text"
                :loading="requestProcessing"
              >
                Update Email Preferences
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col>
              <v-checkbox
                v-model="useTextAlerts"
                label="Text"
                class="v-checkbox-no-margin"
              ></v-checkbox>
              <v-text-field
                v-model="userInfo.phone"
                label="Mobile Phone (no dashes)"
                :required="useTextAlerts"
                :rules="[rules.phoneLength, rules.phone]"
                maxlength="10"
                counter
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Notify me when:
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-for="(notification, i) in dbNotifications"
                :key="'text_notification_checkbox_' + i"
                v-model="textNotifications"
                :label="notification.description"
                :value="notification.id"
                color="blue"
                class="v-checkbox-no-margin"
                :readonly="!useTextAlerts"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                @click="savePreferences"
                class="orange-bg white--text"
                :loading="requestProcessing"
              >
                Update Text Preferences
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="confirmDialog" persistent max-width="290">
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          Settings have been updated
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="green darken-1" text @click="confirmDialog = false">
                Cancel
              </v-btn> -->
          <v-btn color="green darken-1" text @click="confirmDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  PUT_DATA,
  API_USER_INFO,
  GET_QUERY,
  API_CURRENT_USER_INFO,
  API_NOTIFICATIONS
} from "@/core/store/crud.module";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";

export default {
  components: {
    MediaUploader
  },
  data: () => ({
    isLoading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    userInfo: {},
    dbNotifications: [],
    phoneNumber: "",
    useEmailAlerts: false,
    useTextAlerts: false,
    emailNotifications: [],
    textNotifications: [],
    newPassword: "",
    confirmNewPassword: "",
    attachmentsObj: [{ id: 1, url: null }],
    profilePictureObj: { id: 1, url: null },
    isFileUploading: false,
    videoExtensions: "mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt",
    confirmDialog: false,
    requestProcessing: false,
    showUpdateUserPictureButton: false,
    userPictureLoading: false,
    rules: {
      phoneLength: value =>
        value.length == 10 || "Phone number must not exceed 10 digits",
      phone: value => {
        const pattern = /^[0-9]{10}$/;
        return pattern.test(value) || "Invalid phone number.";
      }
    }
  }),
  watch: {},
  async created() {
    await this.getUserInfo();
    await this.getNotifications();
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Settings" }]);
    this.isLoading = false;
  },
  methods: {
    async updateUserPicture() {
      this.userInfo.photo = { id: this.profilePictureObj.fileId };
      this.userPictureLoading = true;
      let self = this;

      await self.$store
        .dispatch(PUT_DATA, {
          listName: `${API_USER_INFO}/${self.userInfo.id}`,
          data: self.userInfo
        })
        .then(function() {
          self.$snackbar.showMessage({
            content: "User Picture has been updated."
          });
          self.showUpdateUserPictureButton = false;
          self.userPictureLoading = false;
        })
        .catch(error => {
          self.$snackbar.showMessage({
            content: "User Picture cannot be saved: " + error
          });
          self.userPictureLoading = false;
        });
    },

    async updatePassword() {
      if (this.confirmNewPassword !== this.newPassword) {
        this.$snackbar.showMessage({
          content: "Password and Confirm password should match",
          color: "red"
        });
        return;
      }

      let updatePasswordInfo = {
        identityId: this.userInfo.id,
        password: this.newPassword
      };

      await this.$store
        .dispatch(PUT_DATA, {
          listName: `${API_USER_INFO}/${this.userInfo.id}/password`,
          data: updatePasswordInfo
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.$snackbar.showMessage({
              content: "Password has been updated."
            });
          }
        });
    },
    async getNotifications() {
      let self = this;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_NOTIFICATIONS
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.dbNotifications = response.data.$values.filter(
              el => el.forEmployees
            );
          }
        });
    },
    async getUserInfo() {
      let self = this;

      // .then(userInfo => {
      //   this.user = userInfo;
      // });
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CURRENT_USER_INFO
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.userInfo = response.data;

            self.emailNotifications = self.userInfo.notificationSettings.$values
              .filter(el => el.isEmailEnabled)
              .map(s => s.notificationId);
            self.textNotifications = self.userInfo.notificationSettings.$values
              .filter(el => el.isSmsEnabled)
              .map(s => s.notificationId);

            self.useEmailAlerts = self.emailNotifications.length > 0;
            self.useTextAlerts = self.textNotifications.length > 0;

            if (self.userInfo.photo) {
              self.profilePictureObj.fileId = self.userInfo.photo.fileId;
              self.profilePictureObj.fileUrl = self.userInfo.photo.fileUrl;
              self.profilePictureObj.url = self.userInfo.photo.fileUrl;
            }
          }
        });
    },
    async savePreferences() {
      let self = this;
      // prepare data

      self.userInfo.notificationSettings = [];

      for (let i = 0; i < self.dbNotifications.length; i++) {
        let item = self.dbNotifications[i];

        let emailNotificationEnabled =
          self.useEmailAlerts && self.emailNotifications.indexOf(item.id) > -1;
        let textNotificationEnabled =
          self.useTextAlerts && self.textNotifications.indexOf(item.id) > -1;

        if (emailNotificationEnabled || textNotificationEnabled) {
          self.userInfo.notificationSettings.push({
            notificationId: item.id,
            isEmailEnabled: emailNotificationEnabled,
            isSmsEnabled: textNotificationEnabled
          });
        }
      }

      self.requestProcessing = true;

      await this.$store
        .dispatch(PUT_DATA, {
          listName: `${API_USER_INFO}/${this.userInfo.id}`,
          data: this.userInfo
        })
        .then(function() {
          self.confirmDialog = true;
          self.requestProcessing = false;
        })
        .catch(err => {
          self.requestProcessing = false;
          self.$snackbar.showMessage({
            content: err,
            color: self.snackbarColor
          });
        });
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onChangeUserPicture(file) {
      this.showUpdateUserPictureButton = true;

      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    }
  }
};
</script>
